import axios from 'axios'
export const baseIP = 'localhost'

const baseSetup = {
    purifierAxios: axios.create({
        baseURL: `https://purifierservice.api.alliancedev.in/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    customerAxios: axios.create({
        baseURL: `https://controlnex.api.alliancedev.in/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    staffAxios: axios.create({
        baseURL: `https://staff.api.alliancedev.in/`,
        headers: {
            'Content-Type': 'application/json'
        }
    })

}


const handleTokenError = () => {
    // Redirect the user to the login page or perform any other necessary action
    window.location.href = `http://staff.alliancedev.in`
}

const requestConfigFunction = (config) => {
    let userToken = localStorage.getItem('_tkn_stf')
    if (userToken) {
        config.headers['Authorization'] = `Bearer ${userToken}`;
    }
    return config
}

const requestErrorFunction = (error) => {
    return Promise.reject(error);
}

const responseConfigFunction = (response) => {
    // Handle successful responses here if needed
    return response.data;
}

const responseErrorFunction = (error) => {
    if (error.response && error.response.status === 401) {
        handleTokenError();
    }
    return Promise.reject(error.response.data);
}


// Add an interceptor to purifierAxios for request
baseSetup.purifierAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to purifierAxios for response errors
baseSetup.purifierAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to customerAxios for request
baseSetup.customerAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to customerAxios for response errors
baseSetup.customerAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to staffAxios for request
baseSetup.staffAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to staffAxios for response errors
baseSetup.staffAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);


export const { customerAxios, staffAxios, purifierAxios } = baseSetup


