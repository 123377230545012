import React, { useEffect } from 'react'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../redux/features/userAuthSlice'
import { baseIP } from '../config/axios'
import NotFound from '../pages/404/NotFound'
import Home from '../pages/home/Home'
import EditCustomer from '../pages/edit-customer/EditCustomer'
import SearchCustomer from '../pages/search-customer/SearchCustomer'
import CustomerFullView from '../pages/full-view/CustomerFullView'



function User() {
  const { user } = useSelector((state) => state.userAuth)
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch()
  let isAuthenticated = false

  if (user?.token) {
    isAuthenticated = true
  }

  useEffect(() => {
    const id = searchParams.get('id');
    if (!id && !user?._id) {
      window.location.href = `http://staff.alliancedev.in`
    } else if (id || user?._id) {
      dispatch(loginUser(id || user?._id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Routes>
      {/* Home */}
      <Route path='/' element={<PrivateRoute element={<Home />} isAuthenticated={isAuthenticated} />} />
      <Route path='/customer/search' element={<PrivateRoute element={<SearchCustomer />} isAuthenticated={isAuthenticated} />} />
      <Route path='/customer/:cid/edit' element={<PrivateRoute element={<EditCustomer />} isAuthenticated={isAuthenticated} />} />
      <Route path='/customer/:cid/full-view' element={<PrivateRoute element={<CustomerFullView />} isAuthenticated={isAuthenticated} />} />



      {/* 404 Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

function PrivateRoute({ element, isAuthenticated }) {
  return isAuthenticated ? (
    <Routes>
      <Route path='/' element={element} />
    </Routes>
  ) : ""

}

export default User